/* index.css */

/* Basic styles for the entire app - Uniform Text Size */
body, h1, h2, h3, h4, h5, h6, p, ul, a {
  font-family: Arial, sans-serif;
  color: #555;
  font-size: 14px;
  margin: 10px;
  line-height: 1.5;
}

/* Links */
a {
  color: #007bff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

/* Container styling */
.project-container {
  padding: 20px;
  box-sizing: border-box;
}

/* Ensure links are uniform with the rest of the text */
a {
  font-size: inherit; /* Inherits the same font size (14px) from the body */
  color: #007bff; /* Link color */
  text-decoration: none; /* Remove underline by default */
}

a:hover {
  text-decoration: underline; /* Add underline on hover */
}